* {
    text-decoration: none !important;
}

.menu-bar {
    text-align: center;
}

.hero-section {
    text-align: center;
    width: 100%;
    height: auto;
    margin-top: 55px !important;
    /* position: relative; */
}

.video-bg {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    min-height: auto;
}

/* Specialised.js */

.specialised-main-box {
    text-align: center;
    padding: 160px 0;
    background-color: #f0f0f0;
}

/* Membership.js */

.membership-main-box {
    text-align: center;
    padding: 160px 0;
    background-color: #fff;
}

/* Pricing.js */

.pricing-main-box {
    text-align: center;
    padding: 160px 0;
    background-color: #f0f0f0;
}

.hover-color-change:hover {
    color: #ba0101;
    background-color: transparent !important;
}

/* ContactUs.js */

.contact-us-main-box {
    text-align: center;
    padding: 160px 0;
    background-color: #fff;
}

.contact-us-title-box {
    padding-bottom: 100px;
}

.contact-us-social-media-box {
    text-align: center;
    margin: auto 0;
}

.contact-us-form {
    display: flex;
    margin: auto;
    align-items: flex-start;
    flex-direction: column;
    width: 375px;
}

.contact-us-input {
    width: 100%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #dcdcdc;
}

.contact-us-input:focus {
    border: 2px solid rgba(0, 206, 158, 1);
}

.contact-us-textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
}

textarea:focus {
    border: 2px solid rgba(0, 206, 158, 1);
}

label {
    margin-top: 1rem;
}

/* input[type="submit"] {
    margin-top: 2rem;
    cursor: pointer;
    background: #fff;
    color: #ba0101;
    border: 1px solid #ba0101;
}

input[type="submit"]:hover {
    background: #ffe2e279;
} */

/* Footer.js */

.footer-main-box {
    text-align: center;
}

/* Media Queries */

@media only screen and (max-width: 768px) {
    form {
        display: flex;
        margin: auto;
        align-items: flex-start;
        flex-direction: column;
        width: 375px !important;
    }
}

@media only screen and (max-width: 499px) {
    form {
        display: flex;
        margin: auto;
        align-items: flex-start;
        flex-direction: column;
        width: 100% !important;
    }
}



/* ------------------------------------- */

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
    svg {
      width: 20px !important;
    }
  }

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}